import React, {
  useEffect,
  useState,
  useContext,
  ChangeEvent,
  useCallback,
} from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';

import { toast } from 'react-toastify';
import { ISelect, IProdutoAssociado } from '~/pages/Produto/types';
import { LojaContext } from '~/context/loja';
import api from '~/services/api';

import { useProduto } from '~/pages/Produto/ProdutoContext';
import { CancelButton, ConfirmButton } from '~/components/Buttons';

import { BuscaProduto } from '~/pages/Produto/BuscaProduto';
import { InputAsyncSelect } from '~/components/NovosInputs';
import { useForm } from 'react-hook-form';

interface IModalTransferir {
  show: boolean;
  gtin: string;
  handleModal: (value: boolean) => void;
}

interface FormTransferir {
  cod_produto: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
}

const ModalTransferir: React.FC<IModalTransferir> = ({
  show,
  gtin,
  handleModal,
}) => {
  const { formProduto, changeFormProduto } = useProduto();

  const { loja } = useContext(LojaContext);
  const [produtos, setProdutos] = useState<ISelect[]>([]);
  const [init, setInit] = useState(false);

  const [formTransferir, setFormTransferir] = useState<FormTransferir>({
    cod_produto: {
      value: '',
      isInvalid: true,
      isRequired: true,
    },
  });
  const { register, control } = useForm({
    // resolver: yupResolver(schema),
    // reValidateMode: 'onBlur',
  });
  // useEffect(() => {
  //   const consulta = formTransferir.consulta.value;
  //   if (!consulta || consulta === '') {
  //     setProdutos([]);
  //     return;
  //   }

  //   const reg = produtos.find((produto) => {
  //     return Number(produto.value) === Number(consulta);
  //   });
  //   if (reg) {
  //     return;
  //   }

  //   try {
  //     api
  //       .get(
  //         `/produto/consulta/${loja.cod_loja}/${consulta.toUpperCase()}/${
  //           formProduto.cod_produto.value
  //         }`,
  //       )
  //       .then((res) => {
  //         const { data, success, message } = res.data;

  //         if (!success) {
  //           throw new Error(message);
  //         }
  //         const select = data.map((item: IProdutoAssociado) => {
  //           return {
  //             value: item.cod_produto,
  //             label: `${item.des_produto} - gtin(s) ${item.cod_gtin}`,
  //           };
  //         });
  //         setProdutos(select);
  //       });
  //   } catch (error: any) {
  //     setProdutos([]);
  //   }
  // }, [formTransferir.consulta.value]);

  useEffect(() => {
    if (!show) {
      setFormTransferir({
        ...formTransferir,
        cod_produto: {
          value: '',
          isRequired: true,
          isInvalid: true,
        },
      });
    }
  }, [show]);

  function handleClose() {
    setInit(false);
    handleModal(false);
  }

  async function handleTransferir() {
    try {
      if (validaInputsParaSubmit(formTransferir)) {
        setInit(true);
        return;
      }

      if (formTransferir.cod_produto.value === formProduto.cod_produto.value) {
        toast.warn(
          'Os produtos selecionados são iguais. Por favor, selecione um produto diferente.',
        );
        return;
      }
      setInit(false);

      const reg = {
        cod_produto: formTransferir.cod_produto.value,
        cod_produto_origem: formProduto.cod_produto.value,
        cod_gtin: gtin,
        cod_loja: loja.cod_loja,
      };

      const res = await api.post(`/produto/gtin/transferir`, reg);
      const { success, message } = res.data;

      if (!success) {
        throw new Error(message);
      }

      const newGtins = formProduto.Gtins.gtins.filter((item) => {
        return item.cod_gtin.value !== gtin;
      });

      const newEmbalagens = formProduto.Embalagens.embalagens.filter((item) => {
        return item.cod_gtin.value.value !== gtin;
      });

      changeFormProduto({
        ...formProduto,
        Embalagens: {
          ...formProduto.Embalagens,
          embalagens: newEmbalagens,
        },
        Gtins: {
          ...formProduto.Gtins,
          gtins: newGtins,
        },
      });

      toast.success(message);
      handleClose();
    } catch (error: any) {
      toast.warning(error.message);
      handleClose();
    }
  }

  const validaInputsParaSubmit = useCallback(
    (inputs: FormTransferir): boolean => {
      for (let i = 0; i < Object.keys(inputs).length; i++) {
        if (
          formTransferir[Object.keys(inputs)[i] as keyof FormTransferir]
            .isRequired &&
          formTransferir[Object.keys(inputs)[i] as keyof FormTransferir]
            .isInvalid
        ) {
          return true;
        }
      }
      return false;
    },
    [formTransferir],
  );

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body
        style={{
          font: "14px 'Jost',sans-serif!important",
        }}
      >
        <div className="row">
          <h2
            style={{
              textAlign: 'center',
            }}
          >
            Atenção
          </h2>
          <div className="col-sm-12 col-md-12">
            <p
              style={{
                textAlign: 'center',
                fontSize: '1.25rem',
                marginBottom: '0.5rem',
                color: '#f75e5e',
              }}
            >
              {`TRANSFERIR o código GTIN para outro produto inativará todas as
              suas embalagens em todas as lojas.`}
            </p>
            <p
              style={{
                fontWeight: 500,
                fontSize: '1rem',
                marginTop: '1rem',
                marginBottom: '0.25rem',
              }}
            >{`GTIN ${gtin} vai para:`}</p>
          </div>
          <div className="col-sm-12 col-md-12">
            <BuscaProduto
              label="Produto"
              placeholder="Digite o Código, Descrição ou GTIN para buscar"
              value={formTransferir.cod_produto.value}
              init={init}
              invalid={formTransferir.cod_produto.isInvalid}
              onChange={(produto) => {
                const { value } = produto;
                setFormTransferir({
                  ...formTransferir,
                  cod_produto: {
                    ...formTransferir.cod_produto,
                    value,
                    isInvalid: value === undefined,
                  },
                });
              }}
            />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="d-flex align-items-center">
        <ConfirmButton
          style={{
            padding: '3px 32px',
          }}
          onClick={handleTransferir}
        >
          Transferir
        </ConfirmButton>
        <CancelButton
          onClick={handleClose}
          style={{
            padding: '3px 32px',
          }}
        >
          Cancelar
        </CancelButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalTransferir;
