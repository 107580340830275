import styled from 'styled-components';

export const AlertBox = styled.div`
  position: relative;
  width: auto;
  transition: 0.2s;

  .react-select__control--is-disabled .react-select__placeholder {
    color: #a5adba;
  }

  .alert-class {
    > * {
      border-radius: 4px;
      position: relative;
      transition: 0.2s;
    }
    p {
      position: absolute;
      right: 0;
      border: none;
      color: #cf1919;
      transition: 0.2s;
    }
    .react-select__control {
      border-bottom: 2px solid #cf1919;
    }
    .react-select__control--is-focused {
      border-top: 2px solid;
      border-left: 2px solid;
      border-right: 2px solid;
      border-color: #cf1919;
      border-bottom: 2px solid #cf1919;
    }
    .sc-iqAclL {
      color: #cf1919 !important;
    }
    .lflqij {
      color: #cf1919 !important;
    }
  }
`;

export const DataList = styled.div`
  width: 100%;
  position: absolute;
  top: 118%;
  background-color: #fff;
  border: 1px solid #dfe1e6;
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-shadow: 0px 2px 14px 1px rgba(168, 168, 168, 0.19);
  -moz-box-shadow: 0px 2px 14px 1px rgba(168, 168, 168, 0.19);
  box-shadow: 0px 2px 14px 1px rgba(168, 168, 168, 0.19);
  div {
    padding: 6px 10px;
    border-left: 2px solid transparent;
    cursor: default;
    &:hover {
      background-color: #f4f5f7;
      border-left: 2px solid #1938a6;
    }
    &:checked {
      background-color: #f4f5f7;
      border-left: 2px solid #1938a6;
    }
    b {
      font-size: 12px;
      font-weight: 600;
      color: #5c5c5c;
    }
  }
`;
