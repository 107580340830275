import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ToggleDefault from '~/components/ToggleDefault';
import { SelectType } from '~/components/NovosInputs/InputSelect/protocols';

import { InputSelect, InputPercent } from '~/components/NovosInputs';

import { moedaFormatada } from '~/components/Inputs/InputPercent2/formatMask';

import { useProduto } from '~/pages/Produto/ProdutoContext';

export const AbaIcms: React.FC = () => {
  const {
    tributacoesSaida,
    tributacoesEntrada,
    formProdutoLoja,
    changeFormProdutoLoja,
    register,
    control,
    setValue,
    formState: { errors },
    watchFlgVedado,
  } = useProduto();

  return (
    <>
      <Row>
        <Col sm={12} md={12} lg={12} xl={12}>
          <InputSelect
            label="ICMS Entrada"
            placeholder="Selecione o NCM"
            name="cod_trib_entrada"
            register={register}
            isError={!!errors.cod_trib_entrada}
            control={control}
            options={tributacoesEntrada as SelectType[]}
            changeSelected={() => null}
            isDisabled
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={2} lg={2} xl={2}>
          <ToggleDefault
            labelOnRight
            inLine
            labelText="Crédito Vedado"
            setChecked={watchFlgVedado}
            onSwitch={() => {
              setValue('flg_veda_cred', !watchFlgVedado);
              setValue(
                'flg_veda_cred_lim',
                formProdutoLoja.flg_veda_cred.value
                  ? false
                  : formProdutoLoja.flg_veda_cred_lim.value,
              );
              setValue(
                'per_veda_cred_lim',
                watchFlgVedado ? '' : formProdutoLoja.per_veda_cred_lim.value,
              );
              changeFormProdutoLoja({
                ...formProdutoLoja,
                flg_veda_cred: {
                  ...formProdutoLoja.flg_veda_cred,
                  value: !formProdutoLoja.flg_veda_cred.value,
                },
                flg_veda_cred_lim: {
                  ...formProdutoLoja.flg_veda_cred_lim,
                  value: formProdutoLoja.flg_veda_cred.value
                    ? false
                    : formProdutoLoja.flg_veda_cred_lim.value,
                },
                per_veda_cred_lim: {
                  ...formProdutoLoja.per_veda_cred_lim,
                  value: formProdutoLoja.flg_veda_cred.value
                    ? '0,00'
                    : formProdutoLoja.per_veda_cred_lim.value,
                },
              });
            }}
          />
        </Col>
        <Col sm={12} md={2} lg={2} xl={2}>
          <ToggleDefault
            labelOnRight
            inLine
            labelText="Até o Limite de"
            disabled={!formProdutoLoja.flg_veda_cred.value}
            setChecked={formProdutoLoja.flg_veda_cred_lim.value}
            onSwitch={() => {
              changeFormProdutoLoja({
                ...formProdutoLoja,
                flg_veda_cred_lim: {
                  ...formProdutoLoja.flg_veda_cred_lim,
                  value: !formProdutoLoja.flg_veda_cred_lim.value,
                },
                per_veda_cred_lim: {
                  ...formProdutoLoja.per_veda_cred_lim,
                  value: formProdutoLoja.flg_veda_cred_lim.value
                    ? '0,00'
                    : formProdutoLoja.per_veda_cred_lim.value,
                },
              });
              setValue(
                'flg_veda_cred_lim',
                !formProdutoLoja.flg_veda_cred_lim.value,
              );
              setValue('per_veda_cred_lim', '0,00');
            }}
          />
        </Col>
        <Col sm={12} md={2} lg={2} xl={2}>
          <div style={{ marginTop: '-0.8125rem' }}>
            <InputPercent
              min={0}
              max={100}
              maxLength={5}
              decimals={2}
              autoComplete="off"
              placeholder="0,00"
              control={control}
              isDisabled={
                !formProdutoLoja.flg_veda_cred.value ||
                !formProdutoLoja.flg_veda_cred_lim.value
              }
              name="per_veda_cred_lim"
              register={register}
              isError={!!errors.per_veda_cred_lim}
              showIcon
              onInput={(ev: any) => {
                const serializedValue = moedaFormatada(ev.target.value);

                let valueToFloat = '';

                if (serializedValue.length < 7) {
                  valueToFloat = serializedValue.replace(',', '.');
                } else {
                  valueToFloat = serializedValue.replace('.', '');
                  valueToFloat = valueToFloat.replace(',', '.');
                }

                setValue('per_veda_cred_lim', valueToFloat);
                changeFormProdutoLoja({
                  ...formProdutoLoja,
                  per_veda_cred_lim: {
                    ...formProdutoLoja.per_veda_cred_lim,
                    value: valueToFloat.includes('-')
                      ? valueToFloat.replace('-', '')
                      : valueToFloat,
                  },
                });
              }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12} lg={12} xl={12}>
          <InputSelect
            label="ICMS Saída"
            placeholder="Selecione o NCM"
            name="cod_tributacao"
            register={register}
            isError={!!errors.cod_tributacao}
            control={control}
            options={tributacoesSaida as SelectType[]}
            changeSelected={() => null}
            isDisabled
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12} lg={12} xl={12}>
          <ToggleDefault
            labelOnRight
            inLine
            toggleTitle="Habilitado apenas para tributações do tipo SUBSTITUIÇÃO"
            labelText="Produção Própria (destaca ST na NF de Cliente)"
            disabled={formProdutoLoja.cod_tributacao.value.value !== 3}
            setChecked={false}
            onSwitch={() => false}
          />
        </Col>
      </Row>
    </>
  );
};
