import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import ToggleDefault from '~/components/ToggleDefault';
import { SelectType } from '~/components/NovosInputs/InputSelect/protocols';

import { tipoNaoIncidente } from '~/pages/Produto/types';

import { InputSelect } from '~/components/NovosInputs';

import { formProdutoBlank, MapeamentoValores } from '../../../../protocols';

import { useProduto } from '~/pages/Produto/ProdutoContext';
import { InputSped } from '../../SpedInput';
import { AiOutlineSearch } from 'react-icons/ai';
import ModalSped from '../../ModalSped';
import { useQueryClient } from 'react-query';

export const AbaPisCofins: React.FC = () => {
  const {
    formProduto,
    changeFormProduto,
    register,
    control,
    setValue,
    getValues,
    formState: { errors },
    handleChangeValoresCst,
    desabilitarBuscaModal,
    enableModalSped,
    handleChangeEnabledModal,
    refSaida,
    optionsEntradaPisCofins,
    watchFlgNaoPisCofins,
    hasErroTipoIncidente,
    setHasErroTipoIncidente,
  } = useProduto();

  const queryClient = useQueryClient();

  const handleEditNaoPisCofinsCache = (value: boolean) => {
    queryClient.setQueryData(`edit_nao_pis_cofins_${28}`, value);
  };

  return (
    <>
      <Row>
        <Col sm={12} md={4} lg={3} xl={2}>
          <ToggleDefault
            labelText="Não Incidente PIS-Cofins?"
            setChecked={watchFlgNaoPisCofins}
            onSwitch={() => {
              handleEditNaoPisCofinsCache(true);
              changeFormProduto({
                ...formProduto,
                flg_nao_pis_cofins: {
                  ...formProduto.flg_nao_pis_cofins,
                  value: !watchFlgNaoPisCofins,
                },
                tipo_nao_pis_cofins: formProdutoBlank.tipo_nao_pis_cofins,
              });
              setHasErroTipoIncidente(false);
              setValue('flg_nao_pis_cofins', !watchFlgNaoPisCofins);
              setValue('tipo_nao_pis_cofins', { label: '', value: undefined });
              setValue('des_sped', '');
              setValue('cod_sped', '');
              setValue('cod_tabela', '');
              setValue('reset_pis_cofins', true);
              handleChangeValoresCst('-1' as keyof MapeamentoValores);
            }}
          />
        </Col>
        <Col sm={12} md={4} lg={3} xl={3}>
          <InputSelect
            label="Tipo Não Incidente"
            placeholder="Selecione o motivo"
            name="tipo_nao_pis_cofins"
            register={register}
            isError={hasErroTipoIncidente}
            control={control}
            options={tipoNaoIncidente as SelectType[]}
            isDisabled={!formProduto?.flg_nao_pis_cofins?.value}
            changeSelected={(selected) => {
              const tipoPisCofinsAnt = getValues(`tipo_nao_pis_cofins`);
              if (
                tipoPisCofinsAnt &&
                tipoPisCofinsAnt.value !== selected.value
              ) {
                setHasErroTipoIncidente(false);
                handleEditNaoPisCofinsCache(true);
                setValue('tipo_nao_pis_cofins', selected);
                setValue('des_sped', null);
                setValue('cod_sped', null);
                setValue('cod_tabela', null);
                handleChangeValoresCst(
                  selected.value as keyof MapeamentoValores,
                );
                changeFormProduto({
                  ...formProduto,
                  tipo_nao_pis_cofins: {
                    ...formProduto?.tipo_nao_pis_cofins,
                    value: selected,
                  },
                });
              }
            }}
          />
        </Col>
        <Col sm={12} md={4} lg={6} xl={7}>
          <div className="input-container">
            <InputSped register={register} control={control} />

            <Button
              type="button"
              className="min-button"
              disabled={desabilitarBuscaModal()}
              onClick={handleChangeEnabledModal}
            >
              <AiOutlineSearch color="#fff" />
            </Button>
          </div>
        </Col>
      </Row>
      <ModalSped
        enabledModalSped={enableModalSped}
        handleChangeEnabledModal={handleChangeEnabledModal}
      />
      <Row>
        <Col sm={12} md={4} lg={4} xl={4} ref={refSaida}>
          <InputSelect
            label="Entrada PIS/COFINS"
            name="entrada_pis_cofins"
            register={register}
            options={optionsEntradaPisCofins as SelectType[]}
            isError={false}
            control={control}
            changeSelected={(selected) => {
              setValue('entrada_pis_cofins', selected);
            }}
          />
        </Col>
        <Col sm={12} md={4} lg={4} xl={4}>
          <InputSelect
            label="CST Entrada"
            name="cst_entrada"
            disabled
            register={register}
            isError={false}
            control={control}
            changeSelected={(selected) => {
              setValue('cst_entrada', selected);
            }}
            options={[]}
          />
        </Col>
        <Col sm={12} md={4} lg={4} xl={4}>
          <InputSelect
            label="CST Saída"
            name="cst_saida"
            register={register}
            isError={false}
            control={control}
            disabled
            changeSelected={(selected) => {
              setValue('cst_saida', selected);
            }}
            options={[]}
          />
        </Col>
      </Row>
    </>
  );
};
