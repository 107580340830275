import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { tipoIPI } from '~/pages/Produto/types';
import { SelectType } from '~/components/NovosInputs/InputSelect/protocols';

import { useProduto } from '~/pages/Produto/ProdutoContext';

import {
  InputSelect,
  InputPercent,
  InputMoney,
} from '~/components/NovosInputs';
import { moedaFormatada } from '~/components/Inputs/InputPercent2/formatMask';

export const AbaIpi: React.FC = () => {
  const {
    formProduto,
    changeFormProduto,
    register,
    control,
    setValue,
    formState: { errors },
    ipiHabilitar,
    handleTipoIPI,
  } = useProduto();

  return (
    <>
      <Row>
        <Col sm={12} md={4} lg={2} xl={2}>
          <InputSelect
            label="Tipo de IPI"
            name="ipi_tipo"
            register={register}
            isError={!!errors.ipi_tipo}
            control={control}
            options={tipoIPI as SelectType[]}
            changeSelected={(selected) => {
              const { value } = selected;

              handleTipoIPI(value);
              setValue('ipi_tipo', selected);

              setValue('ipi_val', '0,00');

              changeFormProduto({
                ...formProduto,
                ipi_tipo: {
                  ...formProduto.ipi_tipo,
                  value: selected,
                },
                ipi_val: {
                  ...formProduto.ipi_val,
                  value: 0,
                },
              });
            }}
          />
        </Col>
        <Col sm={12} md={2} lg={2} xl={2}>
          {formProduto.ipi_tipo.value &&
            (formProduto.ipi_tipo.value.value === undefined ||
              Number(formProduto.ipi_tipo.value.value) <= 0) && (
              <InputPercent
                label="IPI Entrada"
                min={0}
                max={100}
                step="2"
                maxLength={5}
                placeholder="0,00"
                isDisabled={
                  formProduto.ipi_tipo.value.value === undefined ||
                  formProduto.ipi_tipo.value.value === -1
                }
                name="ipi_val"
                register={register}
                control={control}
                isError={!!errors.ipi_val}
                showIcon
                onInput={(ev: any) => {
                  const { value } = ev.target;
                  const serializedValue = moedaFormatada(value);
                  changeFormProduto({
                    ...formProduto,
                    ipi_val: {
                      ...formProduto.ipi_val,
                      value: serializedValue,
                    },
                  });
                }}
              />
            )}

          {formProduto.ipi_tipo.value.value === 1 && (
            <InputMoney
              label="IPI Entrada"
              min={0.01}
              placeholder="0,00"
              register={register}
              name="ipi_val"
              isError={!!errors.ipi_val}
              showIcon
              control={control}
              onInput={(ev: any) => {
                const serializedValue = moedaFormatada(ev.target.value);

                changeFormProduto({
                  ...formProduto,
                  ipi_val: {
                    ...formProduto.ipi_val,
                    value: serializedValue,
                  },
                });
              }}
            />
          )}
        </Col>
      </Row>
    </>
  );
};
